<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-resv-menu></admin-resv-menu>
                        </v-col>
                        <v-col cols="12" md="10" class="">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    월별예약현황
                                </v-col>
                                <v-col>
                                    <div style="display: flex;    justify-content: center;">
                                        <span class="material-icons material-icons-btn" @click="navClick(0)">
                                            keyboard_double_arrow_left
                                        </span>
                                        <span class="material-icons material-icons-btn" @click="navClick(1)">
                                            keyboard_arrow_left
                                        </span>
                                        <div style="font-size: 1rem; font-weight: bold; margin: 0 4px;">
                                            {{year_month}}
                                        </div>
                                        <span class="material-icons material-icons-btn" @click="navClick(2)">
                                            keyboard_arrow_right
                                        </span>
                                        <span class="material-icons material-icons-btn" @click="navClick(3)">
                                            keyboard_double_arrow_right
                                        </span>
                                    </div>

                                </v-col>
                                <v-col cols="12" md="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b table-cal" style="min-width: 500px;">
                                        <thead>
                                        <tr>
                                            <th class="red--text">일</th><!--1-->
                                            <th>월</th><!--2-->
                                            <th>화</th><!--3-->
                                            <th>수</th><!--4-->
                                            <th>목</th><!--5-->
                                            <th>금</th><!--6-->
                                            <th class="blue--text">토</th><!--7-->
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(week, index) in monthListToWeek" :key="index">
                                            <template v-if="week.length < 7">
                                                <td v-for="i in 7">
                                                    <template v-for="(day, index3) in week">
                                                        <div v-if="day.dayOfWeek == i"
                                                             :class="{'table-cal-today':day.day === date.day && date.month === date_now.month && date.year === date_now.year}">
                                                            <div :class="{ 'red--text': day.dateDayCode === '1', 'blue--text': day.dateDayCode === '7'}"
                                                                 class="calender-num">{{day.day}}
                                                            </div>
                                                            <div class="calender-resv-link">
                                                                <a @click="getResvListToDate(day)">[예약보기]</a>
                                                            </div>
                                                            <template v-for="roomInfo in day.roomList">
                                                                <div class="calender-room">
                                                                    <template v-if="roomInfo.resvGubun === '0'">
                                                                        <div class="label-resv-0">예</div>
                                                                    </template>
                                                                    <template v-else-if="roomInfo.resvGubun === '1'">
                                                                        <div class="label-resv-1">대</div>
                                                                    </template>
                                                                    <template v-else-if="roomInfo.resvGubun === '2'">
                                                                        <div class="label-resv-2">완</div>
                                                                    </template>

                                                                    <div class="calender-room-text">
                                                                        <template
                                                                                v-if="roomInfo.resvGubun === '1' || roomInfo.resvGubun === '2'">
                                                                            <a @click="rowClick(roomInfo)"
                                                                               class="calender-room-link">{{roomInfo.roomNm}}</a>
                                                                        </template>
                                                                        <template v-else>
                                                                            {{roomInfo.roomNm}}
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </template>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td v-for="(day, index2) in week" :key="index2"
                                                    :class="{'table-cal-today':day.day === date.day && date.month === date_now.month && date.year === date_now.year}">
                                                    <div :class="{ 'red--text': day.dateDayCode === '1', 'blue--text': day.dateDayCode === '7'}"
                                                         class="calender-num">
                                                        {{day.day}}
                                                    </div>
                                                    <div class="calender-resv-link">
                                                        <a @click="getResvListToDate(day)">[예약보기]</a>
                                                    </div>
                                                    <template v-for="roomInfo in day.roomList">
                                                        <div class="calender-room">
                                                            <template v-if="roomInfo.resvGubun === '0'">
                                                                <div class="label-resv-0">예</div>
                                                            </template>
                                                            <template v-else-if="roomInfo.resvGubun === '1'">
                                                                <div class="label-resv-1">대</div>
                                                            </template>
                                                            <template v-else-if="roomInfo.resvGubun === '2'">
                                                                <div class="label-resv-2">완</div>
                                                            </template>
                                                            <div class="calender-room-text">
                                                                <template
                                                                        v-if="roomInfo.resvGubun === '1' || roomInfo.resvGubun === '2'">
                                                                    <a @click="rowClick(roomInfo)"
                                                                       class="calender-room-link">{{roomInfo.roomNm}}</a>
                                                                </template>
                                                                <template v-else>
                                                                    {{roomInfo.roomNm}}
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </td>
                                            </template>
                                        </tr>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AdminResvMenu from '../../components/AdminResvMenu'

    export default {
        name: 'AdminResvCal',
        components: {
            AdminResvMenu
        },
        data: () => ({
            resvList: [],
            resvInfo: {},
            resvGubun: "",
            exResvGubun: "1",

            menuCnt: {
                total: 0,
                menu1: 0,
                menu2: 0,
                menu3: 0,
                menu4: 0,
                menu5: 0,
            },
            dateString: "",
            date: {
                year: "",
                month: "",
                day: ""
            },
            date_now: {
                year: "",
                month: "",
                day: ""

            },
            year_month: "",
            monthList: [],
            monthListToWeek: [],


        }),
        created: function () {

            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            let today = new Date();

            this.date_now.year = today.getFullYear();
            this.date_now.month = ('0' + (today.getMonth() + 1)).slice(-2);
            this.date_now.day = ('0' + today.getDate()).slice(-2);


            this.date.year = today.getFullYear();
            this.date.month = ('0' + (today.getMonth() + 1)).slice(-2);
            this.date.day = ('0' + today.getDate()).slice(-2);

            this.dateString = this.date.year + '-' + this.date.month + '-' + this.date.day;
            this.year_month = this.date.year + "년 " + this.date.month + "월";

            //let monthVal = year+"-"+month+"-"+date;

            this.getList(this.dateString);

        },
        methods: {
            getList(val) {

                let formdata = {"month": val};

                return this.$store.dispatch("admin/getMonthList", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.monthListToWeek = resp.monthListToWeek;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },

            navClick(val) {
                let d = new Date(this.date.year, this.date.month - 1, this.date.day);
                let d_after = new Date();

                switch (val) {
                    case 0: { // 1년전
                        d_after = new Date(d.setFullYear(d.getFullYear() - 1));
                        break;
                    }
                    case 1: { // 1달전
                        d_after = new Date(d.setMonth(d.getMonth() - 1));
                        break;
                    }
                    case 2: { // 1달후
                        d_after = new Date(d.setMonth(d.getMonth() + 1));
                        break;
                    }
                    case 3: { // 1년후
                        d_after = new Date(d.setFullYear(d.getFullYear() + 1));
                        break;
                    }

                    default: {

                        return;
                    }
                }

                this.date.year = d_after.getFullYear();
                this.date.month = ('0' + (d_after.getMonth() + 1)).slice(-2);
                this.date.day = ('0' + d_after.getDate()).slice(-2);

                this.dateString = this.date.year + '-' + this.date.month + '-' + this.date.day;
                this.year_month = this.date.year + "년 " + this.date.month + "월";

                this.getList(this.dateString);


            },
            rowClick(row) {

                let options = 'top=10, left=10, width=750, height=800, status=no, menubar=no, toolbar=no, resizable=no';
                let route = '/popup/resv/info?resvId=' + row.resvId;
                let popNm = "상세정보" + row.resvId;
                window.open(route, popNm, options);

            },
            getResvListToDate(val) {

                let options = 'top=10, left=10, width=1100, height=800, status=no, menubar=no, toolbar=no, resizable=no';
                let route = '/popup/resv/listToDate?resvDate=' + val.date;
                let popNm = "상세정보" + val.date;
                window.open(route, popNm, options);
            }


        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {},

    }
</script>

<style>

</style>

